<form [formGroup]="formGroup">
  @if (!listSelection) {
    <div [class.input-container]="rangeSelection">
      <div [class.clr-error]="formGroup.get('ipStartValue')?.dirty && formGroup.get('ipStartValue')?.invalid">
        <input
          [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
          formControlName="ipStartValue"
          type="text"
          class="clr-input standard-input"
          maxlength="15"
        />
        @if (formGroup.dirty && formGroup.get('ipStartValue')?.hasError('pattern')) {
          <span class="clr-subtext">
            {{ 'ERROR_MESSAGES.INVALID_IP_ADDRESS' | translate }}
          </span>
        }
      </div>
      @if (rangeSelection) {
        <span class="p2">&amp;</span>
        <div [class.clr-error]="formGroup.get('ipEndValue')?.dirty && formGroup.get('ipEndValue')?.invalid">
          <input
            [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
            formControlName="ipEndValue"
            type="text"
            class="clr-input standard-input"
            maxlength="15"
          />
          @if (formGroup.dirty && formGroup.get('ipEndValue')?.hasError('pattern')) {
            <span class="clr-subtext">
              {{ 'ERROR_MESSAGES.INVALID_IP_ADDRESS' | translate }}
            </span>
          }
        </div>
      }
    </div>
  } @else {
    <dpa-type-ahead-multi
      [placeholderText]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
      [selectedItems]="selectedItems"
      [keyBy]="unwrapValue"
      [searchFormatter]="unwrapValue"
      [labelFormatter]="unwrapValue"
      [createCustomItem]="wrapValue"
      [delimiterSupported]="true"
      [hideArrow]="true"
      [hideActionButtons]="true"
      [noValueFoundText]="'COMMON_MESSAGES.IP_ADDRESS_ADD_DESCRITION'"
      [customHelpText]="'COMMON_MESSAGES.PRESS_ENTER_TO_ADD_VALUE'"
      (selectedItemsChange)="onSelectedItemsChange($event)"
    />
  }
</form>
