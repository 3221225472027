<input
  [placeholder]="selectedValues.length === 0 ? ('COMMON_ACTIONS.ENTER_VALUE' | translate) : ''"
  [ngModel]="query"
  (ngModelChange)="searchValues($event)"
  (focus)="onInputFocus()"
  (blur)="onInputBlur()"
  class="clr-input tree-filter-input"
  type="text"
  autocomplete="off"
/>

@if (isOpen && orgHierarchy) {
  <div
    [class.align-right]="dropdownAlignRight"
    (mousedown)="$event.preventDefault()"
    (click)="$event.stopPropagation()"
    (keyup.enter)="$event.stopPropagation()"
    class="tree-dropdown"
  >
    @if (getDisplayRoot()) {
      <div class="treeview-item-container">
        @if (this.query && this.query.length < MIN_CHAR_FOR_SEARCH) {
          <clr-alert
            clrAlertType="info"
            [clrAlertClosable]="false"
          >
            <clr-alert-item class="alert-item">
              <span class="alert-text">
                {{ 'COMMON_MESSAGES.MIN_CHAR_SEARCH_INFO' | translate: { minChars: MIN_CHAR_FOR_SEARCH } }}
              </span>
            </clr-alert-item>
          </clr-alert>
        } @else {
          <div
            *ngTemplateOutlet="
              itemTemplate;
              context: { item: getDisplayRoot(), onItemSelectedChange: onItemSelectedChange, selectedValuesIndex: selectedValuesIndex }
            "
          ></div>
        }
      </div>
    }
    @if (!getDisplayRoot()) {
      <div class="no-results-container">
        <div class="no-results-message">
          {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_NO_VALUE_FOUND' | translate }}
        </div>
      </div>
    }
  </div>
}

@if (selectedValues.length > 0 && showSelectedOrgs) {
  <ul class="list-unstyled">
    @for (selectedValue of selectedValues.slice(0, 3); track selectedValue) {
      <li>
        <div>
          <span class="label">
            <span>{{ treeNodesById[selectedValue]?.text }}</span>
            <a
              (click)="removeSelectedValue(selectedValue)"
              class="badge badge-clear"
              aria-label="Close"
              aria-hidden="true"
            >
              <cds-icon shape="times" />
            </a>
          </span>
        </div>
      </li>
    }
    @if (selectedValues.length > 3) {
      <li>
        <div class="signpost-item">
          <div class="trigger-item">
            <clr-signpost>
              <button
                clrSignpostTrigger
                class="btn label"
              >
                {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_ITEMS_MORE' | translate: { count: selectedValues.length - 3 } }}
              </button>
              <clr-signpost-content
                *clrIfOpen
                [clrPosition]="SIGNPOST_POSITION"
              >
                <ul>
                  @for (selectedValue of selectedValues.slice(3); track selectedValue) {
                    <li class="label">
                      <span>{{ treeNodesById[selectedValue]?.text }}</span>
                      <a
                        (click)="removeSelectedValue(selectedValue)"
                        class="badge badge-clear"
                        aria-label="Close"
                        aria-hidden="true"
                      >
                        <cds-icon shape="times" />
                      </a>
                    </li>
                  }
                </ul>
              </clr-signpost-content>
            </clr-signpost>
          </div>
        </div>
      </li>
    }
  </ul>
}

<ng-template
  #itemTemplate
  let-item="item"
  let-onItemSelectedChange="onItemSelectedChange"
  let-selectedValuesIndex="selectedValuesIndex"
>
  <div class="form-group tree-row">
    <cds-icon
      [class.visible]="item.children.length"
      [class.collapsed]="!item.expanded"
      (click)="item.expanded = !item.expanded"
      class="caret-icon clickable"
      shape="angle"
      aria-hidden="true"
    />
    <div
      (click)="toggleNodeSelected(item)"
      (keyup.enter)="toggleNodeSelected(item)"
      class="checkbox-container clickable"
    >
      <input
        [ngModel]="!!selectedValuesIndex[item.id]"
        class="tree-row-checkbox"
        type="checkbox"
      />
      <span class="tree-row-label">
        {{ item.text }}
      </span>
    </div>
  </div>
  @if (item.expanded) {
    <div class="treeview-child-container">
      @for (child of item.children; track child) {
        <div
          *ngTemplateOutlet="
            itemTemplate;
            context: { item: child, onItemSelectedChange: onItemSelectedChange, selectedValuesIndex: selectedValuesIndex }
          "
        ></div>
      }
    </div>
  }
</ng-template>
