<dpa-type-ahead-multi
  [placeholderText]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
  [selectedItems]="selectedItems"
  [keyBy]="unwrapValue"
  [searchFormatter]="unwrapValue"
  [labelFormatter]="unwrapValue"
  [createCustomItem]="wrapValue"
  [delimiterSupported]="true"
  [hideArrow]="true"
  [hideActionButtons]="true"
  [noValueFoundText]="'COMMON_MESSAGES.TYPE_AHEAD_MULTI_ADD_DESCRIPTION'"
  [customHelpText]="'COMMON_MESSAGES.PRESS_ENTER_TO_ADD_VALUE'"
  (selectedItemsChange)="onSelectedItemsChange($event)"
/>
